import React, { useState, useEffect } from "react";
import { Card, Button, Popover, Affix, Table, Tag, Typography } from "antd";
import moment from "moment";
import classNames from "classnames";
import {
  ClockCircleOutlined,
  UserOutlined,
  CalendarOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import { calculateTimeLeft } from "../../helpers";

export interface IIngredientsColumn {
  title: string;
  dataIndex: string;
  key: string;
}

export interface IIngredientsData {
  key: string | number;
  ingredient: string;
}

const { Paragraph } = Typography;

export interface IEvent {
  isHost?: boolean;
  eventId?: string;
  userEmail?: string;
  ingredientsData: string[];
  imageUrl: string;
  title: string;
  prepInstructions: any;
  username: string;
  date: any;
  videoLink?: any;
  noSidebar?: boolean;
  handleButtonPress?: () => void;
  tags?: {}[];
}

export const Event = ({
  isHost,
  ingredientsData,
  imageUrl,
  title,
  prepInstructions,
  username,
  videoLink,
  date,
  tags,
  noSidebar = false,
  handleButtonPress,
}: IEvent) => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const timerComponents: any = [];
  const columns = [
    {
      title: "Ingredients",
      dataIndex: "ingredient",
      key: "ingredient",
    },
  ];

  const instructions = () => {
    return {
      __html: prepInstructions,
    };
  };

  const eventClasses = classNames({
    Event: true,
    "Event--no-sidebar": noSidebar,
  });

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span>
        {timeLeft[interval]} {interval}{" "}
      </span>
    );
  });

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 5000);
  });

  const buttonText = () => {
    if (isHost) return "Start Call";
    return "Join Call";
  };

  const ingredientsDataForAntList = () => {
    let ingredientsList: IIngredientsData[] = [];

    if (ingredientsData.length > 0) {
      ingredientsData.map((ingredient) =>
        ingredientsList.push({ key: ingredient, ingredient: ingredient })
      );
    }

    return ingredientsList;
  };

  return (
    <div className={eventClasses}>
      <div className="Event__meta">
        <h1>{title}</h1>
        <div
          style={{
            backgroundImage: `url('${imageUrl}')`,
          }}
          className="Event__meta__img"
        />

        <div className="Event__meta__tags">
          {tags &&
            tags.map((tag) => {
              return <Tag color="magenta">{tag}</Tag>;
            })}
        </div>
        {videoLink && (
          <>
            <h2>Video Link</h2>
            <div>
              <Paragraph copyable>{console.log(videoLink)}</Paragraph>
            </div>
          </>
        )}

        <h2>Date</h2>
        <div>
          {moment(new Date(date)).format("MM-DD-YYYY")} @
          {moment(new Date(date)).format("h:mma")}
        </div>

        <h2>Preparation</h2>
        <div dangerouslySetInnerHTML={instructions()} />

        <div>
          <h2>Ingredients</h2>
          <Table
            columns={columns}
            dataSource={ingredientsDataForAntList()}
            showHeader={false}
            pagination={false}
          />
        </div>
      </div>
      {!noSidebar && (
        <aside>
          <Affix offsetTop={80}>
            <Card
              title={"Event info"}
              extra={
                <a href="#" style={{ fontSize: "1rem" }}>
                  <PrinterOutlined />
                </a>
              }
            >
              <p>
                <UserOutlined /> {username}
              </p>
              <p>
                <CalendarOutlined /> {date}
              </p>
              <p>
                <ClockCircleOutlined />{" "}
                {timerComponents.length ? (
                  timerComponents
                ) : (
                  <span>Time's up!</span>
                )}
              </p>
              <Popover
                content={
                  "Button will be clickable within 15 min of event start"
                }
              >
                <Button
                  className="Button--secondary"
                  onClick={handleButtonPress}
                >
                  {buttonText()}
                </Button>
              </Popover>
            </Card>
          </Affix>
        </aside>
      )}
    </div>
  );
};
