import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Header, Footer } from "../";
import "normalize.css";
import "antd/dist/antd.css";
import "../../styles/Main.scss";

type IProps = {
  children: React.ReactNode;
};

export const Layout = ({ children }: IProps) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div>
        <main>{children}</main>
        <Footer />
      </div>
    </>
  );
};
