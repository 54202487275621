import React, {
  useState,
  createContext,
  Dispatch,
  SetStateAction,
} from "react";
import { ICookingEvent } from "../models";

type Context = {
  newCookingEvent: ICookingEvent;
  setNewCookingEvent: Dispatch<SetStateAction<ICookingEvent>>;
};

// Need to initialize it or Typescript gets mad
const initialContext: Context = {
  newCookingEvent: {
    title: "",
    ingredientsList: [],
    preparation: "",
    videoLink: "",
    featuredImage: undefined,
    tags: undefined,
    date: new Date(),
  },
  setNewCookingEvent: (): void => {
    throw new Error("setContext function must be overridden");
  },
};

export const NewCookingEventContext = createContext<Context>(initialContext);

interface IProps {
  children: React.ReactNode;
}

export const NewCookingEventProvider = ({ children }: IProps) => {
  const [newCookingEvent, setNewCookingEvent] = useState<ICookingEvent>({
    title: "",
    ingredientsList: [],
    preparation: "",
    tags: undefined,
    featuredImage: undefined,
    date: new Date(),
  });

  return (
    <NewCookingEventContext.Provider
      value={{ newCookingEvent, setNewCookingEvent }}
    >
      {children}
    </NewCookingEventContext.Provider>
  );
};
