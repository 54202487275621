import * as React from "react";
import { navigate } from "@reach/router";
import { isLoggedIn } from "../../services";

type IProps = {
  component: React.ComponentType;
  location: string;
};

export const PrivateRoute = ({
  component: Component,
  location,
  ...rest
}: IProps) => {
  if (!isLoggedIn()) {
    navigate(`/`);
    return null;
  }
  return <Component {...rest} />;
};
