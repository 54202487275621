import React from "react";
import { Kitchen } from "../../icons";
import { LoginModal } from "../Header";

type IProps = {
  title: string;
  subtitle: string;
  secondary?: boolean;
  needsHeaderMarginFix?: boolean;
};

export const Banner = ({
  title,
  subtitle,
  secondary,
  needsHeaderMarginFix,
}: IProps) => (
  <section
    className={`Banner${needsHeaderMarginFix ? " Banner__header-fix" : ""}${
      secondary ? " Banner__secondary" : ""
    }`}
  >
    <div className="Banner__inner">
      <Kitchen />
      <div className="Banner__inner--copy">
        <div>
          <h1>{title}</h1>
          <p>{subtitle}</p>
          <LoginModal buttonText="Sign up for beta" />
        </div>
      </div>
    </div>
  </section>
);
