import React from "react";
import { Button } from "antd";
import { Kitchen } from "../../icons";

type IProps = {
  title: string;
  subtitle: string;
  secondary?: boolean;
  needsHeaderMarginFix?: boolean;
  noForm?: boolean;
};

export const BannerThanks = ({
  title,
  subtitle,
  secondary,
  needsHeaderMarginFix,
  noForm = false,
}: IProps) => (
  <section
    className={`BannerThanks${
      needsHeaderMarginFix ? " BannerThanks__header-fix" : ""
    }${secondary ? " BannerThanks__secondary" : ""}`}
  >
    <div className="BannerThanks__inner">
      <div className="BannerThanks__inner--copy">
        <div>
          <h1>{title}</h1>
          <p>{subtitle}</p>
        </div>{" "}
        <Kitchen />
      </div>
    </div>
  </section>
);
