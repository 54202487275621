import * as React from "react";

export const Kitchen = ({ ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-bx-workspace="master"
    viewBox="0 0 500 500"
  >
    <g
      className="st2"
      opacity="0.23"
      transform="matrix(.40397 0 0 .40397 49.63 63.07)"
    >
      <path
        fill="none"
        stroke="#474747"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="6"
        d="M541.4 267.9L541.4 298.9 479.5 298.9"
        className="st3"
      ></path>
      <path
        fill="none"
        stroke="#474747"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="6"
        d="M603.2 298.9L541.4 298.9 541.4 267.9"
        className="st3"
      ></path>
      <path
        fill="none"
        stroke="#474747"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="6"
        d="M510.4 298.9H572.3V329.79999999999995H510.4z"
        className="st3"
      ></path>
      <path
        fill="none"
        stroke="#474747"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="6"
        d="M558.9 360.7L541.4 360.7 541.4 329.8 603.2 329.8"
        className="st3"
      ></path>
      <path
        fill="none"
        stroke="#474747"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="6"
        d="M479.5 329.8L541.4 329.8 541.4 360.7 491.2 360.7"
        className="st3"
      ></path>
      <path
        fill="none"
        stroke="#474747"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="6"
        d="M458.4 298.9L510.4 298.9 510.4 329.8 479.5 329.8"
        className="st3"
      ></path>
      <path
        fill="none"
        stroke="#474747"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="6"
        d="M616.9 329.8L572.3 329.8 572.3 298.9 593.7 298.9"
        className="st3"
      ></path>
    </g>
    <g
      className="st2"
      opacity="0.23"
      transform="matrix(.40397 0 0 .40397 49.63 63.07)"
    >
      <path
        fill="none"
        stroke="#474747"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="6"
        d="M117.6 521.2L117.6 552.1 55.7 552.1"
        className="st3"
      ></path>
      <path
        fill="none"
        stroke="#474747"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="6"
        d="M179.4 552.1L117.6 552.1 117.6 521.2"
        className="st3"
      ></path>
      <path
        fill="none"
        stroke="#474747"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="6"
        d="M86.6 552.1H148.5V583H86.6z"
        className="st3"
      ></path>
      <path
        fill="none"
        stroke="#474747"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="6"
        d="M135.1 614L117.6 614 117.6 583.1 179.4 583.1"
        className="st3"
      ></path>
      <path
        fill="none"
        stroke="#474747"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="6"
        d="M55.7 583.1L117.6 583.1 117.6 614 67.4 614"
        className="st3"
      ></path>
      <path
        fill="none"
        stroke="#474747"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="6"
        d="M34.6 552.1L86.6 552.1 86.6 583.1 55.7 583.1"
        className="st3"
      ></path>
      <path
        fill="none"
        stroke="#474747"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="6"
        d="M193.1 583.1L148.5 583.1 148.5 552.1 169.9 552.1"
        className="st3"
      ></path>
    </g>
    <g
      className="st2"
      opacity="0.23"
      transform="matrix(.40397 0 0 .40397 49.63 63.07)"
    >
      <path
        fill="none"
        stroke="#474747"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="6"
        d="M872 411.6L872 442.5 933.9 442.5"
        className="st3"
      ></path>
      <path
        fill="none"
        stroke="#474747"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="6"
        d="M810.2 442.5L872 442.5 872 411.6"
        className="st3"
      ></path>
      <path
        fill="none"
        stroke="#474747"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="6"
        d="M841.1 442.5H903V473.4H841.1z"
        className="st3"
        transform="rotate(-180 872.017 458)"
      ></path>
      <path
        fill="none"
        stroke="#474747"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="6"
        d="M854.4 504.4L872 504.4 872 473.5 810.2 473.5"
        className="st3"
      ></path>
      <path
        fill="none"
        stroke="#474747"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="6"
        d="M933.9 473.5L872 473.5 872 504.4 895.2 504.4"
        className="st3"
      ></path>
      <path
        fill="none"
        stroke="#474747"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="6"
        d="M955 442.5L902.9 442.5 902.9 473.5 933.9 473.5"
        className="st3"
      ></path>
      <path
        fill="none"
        stroke="#474747"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="6"
        d="M796.4 473.5L841.1 473.5 841.1 442.5 819.6 442.5"
        className="st3"
      ></path>
    </g>
    <path
      fill="none"
      stroke="#333"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="10"
      d="M80.251 356.35L414.371 356.35"
      className="st4"
    ></path>
    <g transform="matrix(.40397 0 0 .40397 49.63 63.07)">
      <path
        fill="#CC6675"
        d="M741.5 375.6H637.6c-6.6 0-12-5.4-12-12v-130c0-6.6 5.4-12 12-12h103.9v154z"
        className="st5"
      ></path>
      <path
        fill="#FEE2CA"
        d="M741.5 375.6h103.9c6.6 0 12-5.4 12-12v-130c0-6.6-5.4-12-12-12H741.5v154z"
        className="st6"
      ></path>
      <path
        fill="#CC6675"
        d="M741.5 375.6h103.9c6.6 0 12-5.4 12-12v-130c0-6.6-5.4-12-12-12H741.5v154z"
        className="st5"
      ></path>
      <path
        fill="none"
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="10"
        d="M845.4 375.6H637.6c-6.6 0-12-5.4-12-12v-130c0-6.6 5.4-12 12-12h207.8c6.6 0 12 5.4 12 12v130c0 6.6-5.4 12-12 12z"
        className="st4"
      ></path>
      <path
        fill="none"
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="10"
        d="M741.5 375.6H637.6c-6.6 0-12-5.4-12-12v-130c0-6.6 5.4-12 12-12h103.9v154z"
        className="st4"
      ></path>
      <path
        fill="none"
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="10"
        d="M741.5 375.6H637.6c-6.6 0-12-5.4-12-12v-130c0-6.6 5.4-12 12-12h103.9v154z"
        className="st4"
      ></path>
      <path
        fill="#F26B79"
        d="M623.1 257.2H744V340H623.1z"
        className="st7"
        transform="rotate(90 683.534 298.576)"
      ></path>
      <path
        fill="#99675E"
        d="M679.3 313.3H687.9V396.1H679.3z"
        className="st8"
        opacity="0.5"
        transform="rotate(90 683.534 354.739)"
      ></path>
      <path
        fill="#99675E"
        d="M678.6 201.7H688.5V284.5H678.6z"
        className="st9"
        opacity="0.53"
        transform="rotate(90 683.534 243.103)"
      ></path>
      <path
        fill="none"
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="8"
        d="M623.1 257.2H744V340H623.1z"
        className="st10"
        transform="rotate(90 683.534 298.576)"
      ></path>
      <path
        fill="none"
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="10"
        d="M741.5 375.6h103.9c6.6 0 12-5.4 12-12v-130c0-6.6-5.4-12-12-12H741.5v154z"
        className="st4"
      ></path>
      <path
        fill="#F26B79"
        d="M739 257.2H859.9V340H739z"
        className="st7"
        transform="rotate(90 799.448 298.576)"
      ></path>
      <path
        fill="#99675E"
        d="M795.2 313.3H803.8000000000001V396.1H795.2z"
        className="st8"
        opacity="0.5"
        transform="rotate(90 799.448 354.739)"
      ></path>
      <path
        fill="#99675E"
        d="M794.5 201.7H804.4V284.5H794.5z"
        className="st9"
        opacity="0.53"
        transform="rotate(90 799.448 243.103)"
      ></path>
      <path
        fill="none"
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="8"
        d="M739 257.2H859.9V340H739z"
        className="st10"
        transform="rotate(90 799.448 298.576)"
      ></path>
      <path
        fill="none"
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="10"
        d="M703.9 278.4L703.9 313.8"
        className="st4"
      ></path>
      <path
        fill="none"
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="10"
        d="M777.3 278.4L777.3 313.8"
        className="st4"
      ></path>
    </g>
    <g transform="matrix(.40397 0 0 .40397 49.63 63.07)">
      <circle
        cx="481.2"
        cy="254"
        r="56"
        fill="#FFE9BD"
        className="st11"
      ></circle>
      <path
        fill="#E2E2E2"
        d="M481.2 220c27.2 0 49.8 19.3 54.9 45 .7-3.6 1.1-7.2 1.1-11 0-30.9-25.1-56-56-56s-56 25.1-56 56c0 3.8.4 7.4 1.1 11 5.1-25.7 27.7-45 54.9-45z"
        className="st12"
      ></path>
      <circle
        cx="481.2"
        cy="254"
        r="56"
        fill="none"
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="10"
        className="st4"
      ></circle>
      <ellipse
        cx="481.2"
        cy="254"
        fill="none"
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="6"
        className="st13"
        rx="46"
        ry="46"
        transform="rotate(-80.781 481.165 253.993)"
      ></ellipse>
      <path
        fill="none"
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="6"
        d="M466.5 225L481.2 254 506 254"
        className="st13"
      ></path>
      <circle cx="481.2" cy="254" r="7.1" fill="#333" className="st1"></circle>
    </g>
    <g transform="matrix(.40397 0 0 .40397 49.63 63.07)">
      <circle
        cx="756.2"
        cy="468.4"
        r="8.2"
        fill="#FFF3E9"
        className="st14"
      ></circle>
      <circle
        cx="756.2"
        cy="468.4"
        r="8.2"
        fill="none"
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="8"
        className="st10"
      ></circle>
      <path
        fill="#F29F4D"
        d="M779.7 535.7H846.8000000000001V726H779.7z"
        className="st15"
      ></path>
      <path
        fill="#DB8945"
        d="M779.7 715.3H846.8000000000001V726H779.7z"
        className="st16"
      ></path>
      <path
        fill="#DB8945"
        d="M779.7 535.7H846.8000000000001V548.5H779.7z"
        className="st16"
      ></path>
      <path
        fill="#F4B47A"
        d="M595.7 495H846.8000000000001V535.7H595.7z"
        className="st17"
      ></path>
      <path
        fill="#DB8945"
        d="M595.7 527.7H846.8000000000001V535.7H595.7z"
        className="st18"
        opacity="0.63"
      ></path>
      <path
        fill="#F9C59B"
        d="M595.7 495H846.8000000000001V503.5H595.7z"
        className="st19"
      ></path>
      <path
        fill="#F29F4D"
        d="M595.7 630.8H687.7V726H595.7z"
        className="st15"
      ></path>
      <path
        fill="#DB8945"
        d="M595.7 715.3H687.7V726H595.7z"
        className="st16"
      ></path>
      <path
        fill="none"
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="10"
        d="M595.7 495H687.7V726H595.7z"
        className="st4"
      ></path>
      <path
        fill="none"
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="10"
        d="M687.7 495H779.7V726H687.7z"
        className="st4"
      ></path>
      <path
        fill="none"
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="10"
        d="M779.7 495H846.8000000000001V726H779.7z"
        className="st4"
      ></path>
      <path
        fill="none"
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="10"
        d="M595.7 535.7H687.7V726H595.7z"
        className="st4"
      ></path>
      <path
        fill="none"
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="10"
        d="M595.7 630.8H687.7V726H595.7z"
        className="st4"
      ></path>
      <path
        fill="#F29F4D"
        d="M595.7 535.7H687.7V630.9000000000001H595.7z"
        className="st15"
      ></path>
      <path
        fill="#DB8945"
        d="M595.7 535.7H687.7V548.5H595.7z"
        className="st16"
      ></path>
      <path
        fill="none"
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="10"
        d="M595.7 535.7H687.7V630.9000000000001H595.7z"
        className="st4"
      ></path>
      <path
        fill="#F29F4D"
        d="M687.7 535.7H779.7V726H687.7z"
        className="st15"
      ></path>
      <path
        fill="#DB8945"
        d="M687.7 715.3H779.7V726H687.7z"
        className="st16"
      ></path>
      <path
        fill="#DB8945"
        d="M687.7 535.7H779.7V548.5H687.7z"
        className="st16"
      ></path>
      <path
        fill="none"
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="10"
        d="M687.7 535.7H779.7V726H687.7z"
        className="st4"
      ></path>
      <path
        fill="#F4B77F"
        d="M664.6 610.1H802.8V663.2H664.6z"
        className="st20"
        transform="rotate(90 733.705 636.667)"
      ></path>
      <path
        fill="#F9C59B"
        d="M707.1 655.8L760.3 585.8 760.3 567.6 745.4 567.6 707.1 618z"
        className="st21"
        opacity="0.39"
      ></path>
      <path
        fill="#F9C59B"
        d="M707.1 683.5L760.3 613.5 760.3 599.5 707.1 669.6z"
        className="st21"
        opacity="0.39"
      ></path>
      <path
        fill="#F9C59B"
        d="M707.1 702.6L760.3 632.6 760.3 625.2 707.1 695.3z"
        className="st21"
        opacity="0.39"
      ></path>
      <path
        fill="none"
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="6"
        d="M664.6 610.1H802.8V663.2H664.6z"
        className="st13"
        transform="rotate(90 733.705 636.667)"
      ></path>
      <path
        fill="#F4B77F"
        d="M618.7 564.1H664.8000000000001V617.2H618.7z"
        className="st20"
        transform="rotate(90 641.705 590.627)"
      ></path>
      <path
        fill="#F9C59B"
        d="M639.6 567.6L615.1 599.9 615.1 613.7 620.8 613.7 655.8 567.6z"
        className="st22"
        opacity="0.43"
      ></path>
      <path
        fill="#F9C59B"
        d="M638.3 613.7L668.3 574.2 668.3 567.6 662.7 567.6 627.7 613.7z"
        className="st22"
        opacity="0.43"
      ></path>
      <path
        fill="none"
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="6"
        d="M618.7 564.1H664.8000000000001V617.2H618.7z"
        className="st13"
        transform="rotate(90 641.705 590.627)"
      ></path>
      <path
        fill="none"
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="10"
        d="M779.7 535.7H846.8000000000001V726H779.7z"
        className="st4"
      ></path>
      <path
        fill="#F4B77F"
        d="M744.2 620.9H882.4000000000001V652.4H744.2z"
        className="st20"
        transform="rotate(90 813.24 636.667)"
      ></path>
      <path
        fill="#F9C59B"
        d="M797.5 656.3L829 614.7 829 576.9 797.5 618.4z"
        className="st23"
        opacity="0.32"
      ></path>
      <path
        fill="#F9C59B"
        d="M797.5 678.4L829 636.9 829 622.9 797.5 664.5z"
        className="st23"
        opacity="0.32"
      ></path>
      <path
        fill="#F9C59B"
        d="M797.5 694L829 652.4 829 645.1 797.5 686.6z"
        className="st23"
        opacity="0.32"
      ></path>
      <path
        fill="none"
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="6"
        d="M744.2 620.9H882.4000000000001V652.4H744.2z"
        className="st13"
        transform="rotate(90 813.24 636.667)"
      ></path>
      <path
        fill="none"
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="10"
        d="M379.7 495H595.7V726H379.7z"
        className="st4"
      ></path>
      <path
        fill="#F29F4D"
        d="M379.7 535.7H595.7V720.3000000000001H379.7z"
        className="st15"
      ></path>
      <path
        fill="#DB8945"
        d="M379.7 710.3H595.7V720.3H379.7z"
        className="st16"
      ></path>
      <path
        fill="#DB8945"
        d="M379.7 535.7H595.7V548.5H379.7z"
        className="st16"
      ></path>
      <path
        fill="none"
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="10"
        d="M379.7 535.7H595.7V720.3000000000001H379.7z"
        className="st4"
      ></path>
      <path
        fill="#CFE5E8"
        d="M412.3 668v-59c0-11.6 9.4-21 21-21H542c11.6 0 21 9.4 21 21v59c0 11.6-9.4 21-21 21H433.4c-11.6 0-21.1-9.4-21.1-21z"
        className="st24"
      ></path>
      <path
        fill="#A7E3E8"
        d="M433.4 675c-3.9 0-7-3.2-7-7v-59c0-3.9 3.2-7 7-7H542c3.9 0 7 3.2 7 7v59c0 3.9-3.2 7-7 7H433.4z"
        className="st25"
      ></path>
      <path
        fill="none"
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="8"
        d="M412.3 668v-59c0-11.6 9.4-21 21-21H542c11.6 0 21 9.4 21 21v59c0 11.6-9.4 21-21 21H433.4c-11.6 0-21.1-9.4-21.1-21z"
        className="st10"
      ></path>
      <path
        fill="none"
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="10"
        d="M527.3 561.5L448.1 561.5"
        className="st4"
      ></path>
      <path
        fill="#F4B47A"
        d="M467.4 407.3H508.09999999999997V623.3H467.4z"
        className="st17"
        transform="rotate(90 487.705 515.333)"
      ></path>
      <path
        fill="#DB8945"
        d="M483.7 423.7H491.7V639.7H483.7z"
        className="st18"
        opacity="0.63"
        transform="rotate(90 487.705 531.667)"
      ></path>
      <path
        fill="#F9C59B"
        d="M483.5 391.3H492V607.3H483.5z"
        className="st19"
        transform="rotate(90 487.705 499.25)"
      ></path>
      <path
        fill="none"
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="10"
        d="M467.4 407.3H508.09999999999997V623.3H467.4z"
        className="st4"
        transform="rotate(90 487.705 515.333)"
      ></path>
      <path
        fill="none"
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="12"
        d="M401 515.3L447.7 515.3"
        className="st26"
      ></path>
      <circle
        cx="527.3"
        cy="515.3"
        r="7.1"
        fill="#EA5050"
        className="st27"
      ></circle>
      <circle
        cx="527.3"
        cy="515.3"
        r="7.1"
        fill="none"
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="4"
        className="st28"
      ></circle>
      <circle
        cx="548.7"
        cy="515.3"
        r="7.1"
        fill="#7DC163"
        className="st29"
      ></circle>
      <circle
        cx="548.7"
        cy="515.3"
        r="7.1"
        fill="none"
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="4"
        className="st28"
      ></circle>
      <circle
        cx="570.2"
        cy="515.3"
        r="7.1"
        fill="none"
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="4"
        className="st28"
      ></circle>
      <circle
        cx="570.2"
        cy="515.3"
        r="7.1"
        fill="#333"
        className="st1"
      ></circle>
      <path
        fill="#FFF3E9"
        d="M729.7 486.5c12.3 0 22.3-13.2 22.3-29.5h-44.6c0 16.3 10 29.5 22.3 29.5z"
        className="st14"
      ></path>
      <path
        fill="#C4CECD"
        d="M740.2 457c0 13.6-7 25-16.4 28.4 1.9.7 3.8 1 5.9 1 12.3 0 22.3-13.2 22.3-29.5h-11.8z"
        className="st30"
        opacity="0.45"
      ></path>
      <path
        fill="none"
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="8"
        d="M729.7 486.5c12.3 0 22.3-13.2 22.3-29.5h-44.6c0 16.3 10 29.5 22.3 29.5z"
        className="st10"
      ></path>
      <path
        fill="none"
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="10"
        d="M711.4 490L748 490"
        className="st4"
      ></path>
      <path
        fill="none"
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="6"
        d="M721.9 422.7s-4 6-4 8.7c0 2.7 8.9 2 8.9 6s-4.5 10.1-4.5 10.1"
        className="st13"
      ></path>
      <path
        fill="none"
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="6"
        d="M736.6 417.1s-4 6-4 8.7c0 2.7 8.9 2 8.9 6s-4.5 10.1-4.5 10.1"
        className="st13"
      ></path>
      <path
        fill="none"
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="6"
        d="M729.3 403L726.9 409.3"
        className="st13"
      ></path>
      <path
        fill="none"
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="10"
        d="M595.7 495H846.8000000000001V535.7H595.7z"
        className="st4"
      ></path>
      <path
        fill="none"
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="8"
        d="M631.1 551L652.4 551"
        className="st10"
      ></path>
      <path
        fill="#F4B77F"
        d="M618.7 656.1H664.8000000000001V709.2H618.7z"
        className="st20"
        transform="rotate(90 641.705 682.706)"
      ></path>
      <path
        fill="#F9C59B"
        d="M639.6 659.7L615.1 691.9 615.1 705.7 620.8 705.7 655.8 659.7z"
        className="st22"
        opacity="0.43"
      ></path>
      <path
        fill="#F9C59B"
        d="M638.3 705.7L668.3 666.3 668.3 659.7 662.7 659.7 627.7 705.7z"
        className="st22"
        opacity="0.43"
      ></path>
      <path
        fill="none"
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="6"
        d="M618.7 656.1H664.8000000000001V709.2H618.7z"
        className="st13"
        transform="rotate(90 641.705 682.706)"
      ></path>
      <path
        fill="none"
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="8"
        d="M631.1 645.1L652.4 645.1"
        className="st10"
      ></path>
      <path
        fill="none"
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="8"
        d="M724.5 551L707.1 551"
        className="st10"
      ></path>
      <path
        fill="none"
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="8"
        d="M810.5 551L797.5 551"
        className="st10"
      ></path>
      <path
        fill="#333"
        d="M522 495h-71c-2.9 0-5.3-2.4-5.3-5.3s2.4-5.3 5.3-5.3h71c2.9 0 5.3 2.4 5.3 5.3s-2.4 5.3-5.3 5.3z"
        className="st1"
      ></path>
      <path
        fill="#FFF3E9"
        d="M534.4 457L568 429.5 563.1 422.7 525 441.8z"
        className="st14"
      ></path>
      <path
        fill="none"
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="10"
        d="M534.4 457L568 429.5 563.1 422.7 525 441.8z"
        className="st4"
      ></path>
      <path
        fill="#DBDDDD"
        d="M520 484.4h-66.9c-10.2 0-18.5-8.3-18.5-18.5v-24.8c0-10.2 8.3-18.5 18.5-18.5H520c10.2 0 18.5 8.3 18.5 18.5V466c0 10.2-8.3 18.4-18.5 18.4z"
        className="st31"
      ></path>
      <path
        fill="#FFF"
        d="M448.1 466v-24.8c0-10.2 8.3-18.5 18.5-18.5h-13.4c-10.2 0-18.5 8.3-18.5 18.5V466c0 10.2 8.3 18.5 18.5 18.5h13.4c-10.2-.1-18.5-8.3-18.5-18.5z"
        className="st32"
        opacity="0.55"
      ></path>
      <path
        fill="#C4CECD"
        d="M520 422.7h-18.4c10.2 0 18.5 8.3 18.5 18.5V466c0 10.2-8.3 18.5-18.5 18.5H520c10.2 0 18.5-8.3 18.5-18.5v-24.8c0-10.3-8.3-18.5-18.5-18.5z"
        className="st33"
      ></path>
      <path
        fill="none"
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="10"
        d="M520 484.4h-66.9c-10.2 0-18.5-8.3-18.5-18.5v-24.8c0-10.2 8.3-18.5 18.5-18.5H520c10.2 0 18.5 8.3 18.5 18.5V466c0 10.2-8.3 18.4-18.5 18.4z"
        className="st4"
      ></path>
      <path fill="#333" d="M422 443.9H434.7V458H422z" className="st1"></path>
      <path
        fill="none"
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="10"
        d="M422 443.9H434.7V458H422z"
        className="st4"
      ></path>
      <ellipse
        cx="487"
        cy="421.8"
        fill="none"
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="10"
        className="st4"
        rx="44.3"
        ry="7.8"
      ></ellipse>
      <path
        fill="none"
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="10"
        d="M481.7 403H491.4V417.7H481.7z"
        className="st4"
      ></path>
      <path
        fill="none"
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="6"
        d="M577.3 392.5L574 398.5 580.5 406.2 574 414"
        className="st13"
      ></path>
      <path
        fill="#D7FFF7"
        d="M464.1 675L519.4 602 498.2 602 442.9 675z"
        className="st34"
      ></path>
      <path
        fill="#D7FFF7"
        d="M487 675l55.4-73h-10.6l-55.4 73H487z"
        className="st34"
      ></path>
    </g>
    <path
      fill="none"
      stroke="#1D2F3D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="10"
      d="M232.263 375.982L264.258 375.982"
      className="st35"
    ></path>
    <path
      fill="none"
      stroke="#1D2F3D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="10"
      d="M218.205 375.982L223.336 375.982"
      className="st35"
    ></path>
    <g transform="matrix(.40397 0 0 .40397 49.63 63.07)">
      <path
        fill="#AECBD9"
        d="M335 726H157c-6.6 0-12-5.4-12-12V242c0-6.6 5.4-12 12-12h178c6.6 0 12 5.4 12 12v472c0 6.6-5.4 12-12 12z"
        className="st36"
      ></path>
      <path
        fill="#C4E7F4"
        d="M159.5 714V242c0-6.6 5.8-12 12.9-12h-14.5c-7.1 0-12.9 5.4-12.9 12v472c0 6.6 5.8 12 12.9 12h14.5c-7.2 0-12.9-5.4-12.9-12z"
        className="st37"
      ></path>
      <path
        fill="#8CA2AE"
        d="M335 230h-22.5c6.6 0 12 5.4 12 12v472c0 6.6-5.4 12-12 12H335c6.6 0 12-5.4 12-12V242c0-6.6-5.4-12-12-12z"
        className="st38"
        opacity="0.89"
      ></path>
      <path
        fill="none"
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="10"
        d="M335 726H157c-6.6 0-12-5.4-12-12V242c0-6.6 5.4-12 12-12h178c6.6 0 12 5.4 12 12v472c0 6.6-5.4 12-12 12z"
        className="st4"
      ></path>
      <path
        fill="none"
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="10"
        d="M347 382H145V242c0-6.6 5.4-12 12-12h178c6.6 0 12 5.4 12 12v140z"
        className="st4"
      ></path>
      <path
        fill="none"
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="10"
        d="M172.3 301.7L172.3 348"
        className="st4"
      ></path>
      <path
        fill="none"
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="10"
        d="M172.3 459.9L172.3 544"
        className="st4"
      ></path>
      <path fill="#64809B" d="M145 382H347V409.3H145z" className="st39"></path>
      <path
        fill="#8CA2AE"
        d="M324 382H347V409.3H324z"
        className="st40"
        opacity="0.71"
      ></path>
      <path
        fill="#64809B"
        d="M145 382H159.5V409.3H145z"
        className="st41"
        opacity="0.26"
      ></path>
      <path
        fill="none"
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="10"
        d="M145 382H347V409.3H145z"
        className="st4"
      ></path>
      <path
        fill="none"
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="6"
        d="M207 395.7L284 395.7"
        className="st13"
      ></path>
    </g>
  </svg>
);
