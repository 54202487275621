import React, { useContext } from "react";
import "react-quill/dist/quill.snow.css";
import { NewCookingEventContext } from "../../../contexts";
const ReactQuill =
  typeof document !== `undefined` ? require("react-quill") : null;

export const PrepWriteup = () => {
  const { newCookingEvent, setNewCookingEvent } = useContext(
    NewCookingEventContext
  );

  return (
    <div className="PrepWriteup">
      <h2>Preparation instructions</h2>

      {document && (
        <ReactQuill
          theme="snow"
          value={newCookingEvent.preparation}
          placeholder="For example, peel the 🥕 before we start. Or maybe pour a glass of your favorite 🍷?"
          onChange={(value: any) => {
            setNewCookingEvent({ ...newCookingEvent, preparation: value });
          }}
        />
      )}
    </div>
  );
};
