export interface IScheduledEvent{    
    recipe: IRecipe,
    room: IRoom,
    participants: Array<IChefyUser>
}

export interface IRoom {
  roomId?: number;
  roomSid?: string;
  roomName?: string;
  participants: number;
  scheduledStartTime: string;
  scheduledEndTime?: string;
  isActive?: boolean;
  roomtypeId: RoomTypeId;
}

export interface IChefyUser {
  cognitoUserId?: string;
  isHost: boolean;
  email: string;
  userName?: string;
}

export interface IRecipe{
    recipeName: string,
    ingredients: string,
    instructions: string,
    tags?: Array<ITags>
}

export interface ITags {
  tagColor: string;
  tagId: number;
  tagName: string;
}

export enum RoomTypeId {
  Free = 1,
}
