import React, { useState, useContext } from "react";
import moment from "moment";
import { DatePicker as AntDatePicker } from "antd";
import { roundMinutes } from "../../../helpers";
import { NewCookingEventContext } from "../../../contexts";

export const DatePicker = () => {
  const { newCookingEvent, setNewCookingEvent } = useContext(
    NewCookingEventContext
  );
  const todaysDate = moment(roundMinutes(new Date()));

  const [dateValue, setDateValue] = useState(todaysDate);

  const disabledDate = (current: any) => {
    // Can not select days before today and today
    return current && current < moment().startOf("day");
  };
  return (
    <div className="DatePicker">
      <h2>Choose a date and time </h2>

      <AntDatePicker
        showTime={{ format: "h:mm a", minuteStep: 15 }}
        style={{ width: "100%" }}
        disabledDate={disabledDate}
        size="large"
        defaultValue={todaysDate}
        format={"MM/DD/YY @h:mm a"}
        onChange={(value) => {
          setDateValue(value);
          setNewCookingEvent({ ...newCookingEvent, date: value });
        }}
      />
    </div>
  );
};
