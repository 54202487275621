import { IUser } from "../models";

export const isBrowser = () => typeof window !== "undefined" && window;

export const getUser = (): IUser =>
  isBrowser() && window.localStorage.getItem("gatsbyUser")
    ? JSON.parse(window.localStorage.getItem("gatsbyUser") || "{}")
    : {};

export const setUser = (user: any) =>
  window.localStorage.setItem("gatsbyUser", JSON.stringify(user));

export const isLoggedIn = () => {
  const user = getUser();
  return !!user.username;
};
export const logout = (callback: () => void) => {
  setUser({});
  callback();
};
