import React, { useState, useContext } from "react";
import { Upload, message } from "antd";
import ImgCrop from "antd-img-crop";
import { NewCookingEventContext } from "../../../contexts";
export interface IImageFile {
  uid: string;
  name: string;
  status: string;
  url: string;
}

export const ImageUploader = () => {
  const [fileList, setFileList] = useState<IImageFile[]>([]);
  const { newCookingEvent, setNewCookingEvent } = useContext(
    NewCookingEventContext
  );

  // Need to turn the Blob into a base 64 to send in cloudinary api
  const getBase64 = (file: Blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  // We can limit sizings and file types here
  const beforeUpload = (file: any) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }

    return isJpgOrPng && isLt2M;
  };

  // This returns a File obj AFTER the api call has been made
  const onChange = ({ file, fileList: newFileList }: any) => {
    setFileList(newFileList);
    setNewCookingEvent({ ...newCookingEvent, featuredImage: file.response });
  };

  return (
    <>
      <h2>Choose a featured image</h2>
      <ImgCrop aspect={2}>
        <Upload
          action="https://api.cloudinary.com/v1_1/doubles/image/upload"
          className="ImageUploader"
          data={async (data: any) => {
            const newData: any = await getBase64(data);
            const cloudinaryPayload = {
              file: newData,
              tags: "chefy",
              upload_preset: "chefyyumyum",
            };

            return cloudinaryPayload;
          }}
          listType="picture-card"
          fileList={fileList as any}
          onChange={onChange}
          beforeUpload={beforeUpload}
        >
          {fileList.length < 1 && "+ Upload"}
        </Upload>
      </ImgCrop>
    </>
  );
};
