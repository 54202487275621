import React, { useState, useEffect, useContext } from "react";
import { Table, Input, InputNumber, Empty, Form, Button } from "antd";
import { RecipeBook } from "../../../icons";
import { NewCookingEventContext } from "../../../contexts";

interface Item {
  key: string;
  ingredient: string;
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: "number" | "text";
  record: Item;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === "number" ? <InputNumber /> : <Input />;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please add or remove an ingredient.`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export const EditableTable = () => {
  const { newCookingEvent, setNewCookingEvent } = useContext(
    NewCookingEventContext
  );

  useEffect(() => {
    const originData: Item[] = [];
    for (let i = 0; i < newCookingEvent.ingredientsList.length; i++) {
      originData.push({
        key: i.toString(),
        ingredient: newCookingEvent.ingredientsList[i],
      });
    }
    setData(originData);
  }, [newCookingEvent]);

  const [form] = Form.useForm();
  const [data, setData] = useState<Item[] | null | undefined>();
  const [editingKey, setEditingKey] = useState("");

  const isEditing = (record: Item) => record.key === editingKey;

  const edit = (record: Item) => {
    form.setFieldsValue({ ingredient: "", ...record });
    setEditingKey(record.key);
  };

  const removeItem = (key: string) => {
    const filteredList = data.filter((item) => {
      return item.key !== key;
    });
    const newIngredientsList = filteredList.map((item) => item.ingredient);

    setNewCookingEvent({
      ...newCookingEvent,
      ingredientsList: newIngredientsList,
    });
    setEditingKey("");
  };

  const save = async (key: React.Key) => {
    try {
      const row = (await form.validateFields()) as Item;

      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setData(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const columns = [
    {
      title: newCookingEvent.title ? newCookingEvent.title : "",
      dataIndex: "ingredient",
      width: "90%",
      editable: true,
    },
    {
      title: "",
      dataIndex: "operation",
      render: (_: any, record: Item) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Button
              type="link"
              size="small"
              style={{ marginRight: 4 }}
              onClick={() => save(record.key)}
            >
              Save
            </Button>
            <Button
              type="link"
              danger
              size="small"
              onClick={() => removeItem(record.key)}
            >
              Remove
            </Button>
          </span>
        ) : (
          <>
            <Button
              type="link"
              size="small"
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
            >
              Edit
            </Button>
          </>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Item) => ({
        record,
        inputType: col.dataIndex === "age" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        locale={{
          emptyText: (
            <Empty
              image={<RecipeBook className="ThreeStepBanner__recipes" />}
              style={{ margin: "3rem" }}
              imageStyle={{
                height: 60,
              }}
              description={<span>This is going to be so yummy</span>}
            ></Empty>
          ),
        }}
        bordered
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={false}
      />
    </Form>
  );
};
