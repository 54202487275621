import React from "react";
import { Row, Col } from "antd";
import { Calendar, Chef, RecipeBook } from "../../icons";

type IProps = {
  title: string;
};

export const ThreeStepBanner = ({ title }: IProps) => (
  <div className="ThreeStepBanner">
    <div className="ThreeStepBanner__inner">
      <Row>
        <Col span={24}>
          <h2 className="ThreeStepBanner__title">{title}</h2>
        </Col>
        <Col xs={24} sm={8}>
          <div className="ThreeStepBanner__step">
            <RecipeBook className="ThreeStepBanner__recipes" />
            <h3>Build your cooking event</h3>
            <p>
              Create an ingredients list, preparation instructions and a date to
              host it.
            </p>
          </div>
        </Col>
        <Col xs={24} sm={8}>
          <div className="ThreeStepBanner__step">
            <Calendar className="ThreeStepBanner__calendar" />

            <div className="ThreeStepBanner__step">
              <h3>Share with friends</h3>
              <p>
                Share with whoever you want to join so everyone can go grocery
                shopping.
              </p>
            </div>
          </div>
        </Col>
        <Col xs={24} sm={8}>
          <div className="ThreeStepBanner__step">
            <Chef className="ThreeStepBanner__chef" />
            <h3>Host a virtual event</h3>
            <p>Attach a video hangout link (i.e. Zoom) and invite others</p>
          </div>
        </Col>
      </Row>
    </div>
  </div>
);
