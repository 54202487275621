import React, { useState } from "react";
import { Link, navigate } from "gatsby";
import { Affix, Button, Menu, Dropdown } from "antd";
import classNames from "classnames";
import { LoginModal } from "./LoginModal";
import { logout, isLoggedIn } from "../../services";

type IProps = {
  siteTitle: string;
};

const loggedInMenu = (
  <Menu>
    <Menu.Item>
      <Link to="/profile">My events</Link>
    </Menu.Item>
    <Menu.Item
      danger
      onClick={() => {
        logout(() => navigate("/"));
      }}
    >
      Logout
    </Menu.Item>
  </Menu>
);

export const Header = ({ siteTitle }: IProps) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const headerClasses = classNames({
    Header: true,
    "Header--scrolled": isScrolled,
  });
  return (
    <Affix
      style={{ top: 0 }}
      onChange={() => {
        setIsScrolled(!isScrolled);
      }}
    >
      <header className={headerClasses}>
        <div className="Header__inner">
          <h1 className="Header__logo">
            <Link to="/">{siteTitle}</Link>
          </h1>
          <input type="checkbox" id="nav-toggle" className="nav-toggle" />{" "}
          {!isLoggedIn() && (
            <nav className="Header__links">
              <LoginModal buttonText="Sign in" isSignIn={true} />
              <LoginModal buttonText="Sign up for beta" />
            </nav>
          )}
          {isLoggedIn() && (
            <nav className="Header__links">
              <Dropdown overlay={loggedInMenu}>
                <Button type="link">
                  <Link to="/profile">Profile</Link>
                </Button>
              </Dropdown>
            </nav>
          )}
          <label htmlFor="nav-toggle" className="nav-toggle-label">
            <span></span>
          </label>
        </div>
      </header>
    </Affix>
  );
};
