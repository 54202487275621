import React, { useState } from "react";
import { Link, navigate } from "gatsby";
import { Form, Input, Modal, Button } from "antd";
import { MailOutlined } from "@ant-design/icons";
import classNames from "classnames";
import { setUser, isLoggedIn } from "../../services";
import { Auth } from "aws-amplify";
import { UserPostData } from "../../models";

type IProps = {
  buttonText: string;
  isSignIn?: boolean;
};

interface IRegistrationValues {
  email: string;
  password: string;
  verifyPassword?: string;
  authCode?: string;
}

export const LoginModal = ({
  buttonText = "Sign up for beta",
  isSignIn = false,
}: IProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [signIn, setSignIn] = useState(false);
  const [signInWithEmail, setSignInWithEmail] = useState(false);
  const [signUpWithEmail, setSignUpWithEmail] = useState(false);

  const [error, setError] = useState();

  const modalClasses = classNames({
    LoginModal: true,
  });

  const handleSubmit = () => {
    setModalOpen(false);
  };

  const handleCancel = () => {
    setModalOpen(false);

    setTimeout(() => {
      setError(undefined);
      setSignIn(false);
      setSignUpWithEmail(false);
      setSignInWithEmail(false);
    }, 200);
  };

  const onFinish = (values: IRegistrationValues) => {
    if (signUpWithEmail) {
      register(values.email, values.password);
    } else {
      login(values.email, values.password);
    }
  };

  const onFinishFailed = () => {
    navigate("/");
  };

  const register = async (email: string, password: string) => {
    try {
      await Auth.signUp({
        username: email.toLocaleLowerCase(),
        password,
        attributes: { email },
      });

      navigate("/auth");
    } catch (err) {
      setError(err.message);
    }
  };

  const login = async (email: string, password: string) => {
    try {
      await Auth.signIn(email, password);
      const user = await Auth.currentAuthenticatedUser();

      const userPostData: UserPostData = {
        userGuid: user.attributes.sub,
        email: user.attributes.email,
        userName: user.attributes.username ? user.attributes.username : "",
      };

      const userInfo = {
        ...user.attributes,
        username: user.username,
      };
      setUser(userInfo);
      navigate("/thankyou");
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className={modalClasses}>
      <Button
        type={isSignIn ? "link" : "primary"}
        onClick={() => {
          if (isLoggedIn()) {
            navigate("/thankyou");
          } else {
            if (isSignIn) {
              setSignIn(true);
            }
            setModalOpen(true);
          }
        }}
      >
        {buttonText}
      </Button>
      <Modal
        visible={modalOpen}
        onOk={handleSubmit}
        onCancel={handleCancel}
        footer={null}
      >
        <div className="LoginModal__content">
          {signIn && !signInWithEmail && !signUpWithEmail && (
            <div>
              <h2>Welcome back!</h2>
              <p>
                Sign in to get personalized story recommendations, follow
                authors and topics you love, and interact with stories.
              </p>
              <div className="LoginModal__content--btns">
                <Button
                  onClick={() => setSignInWithEmail(true)}
                  icon={<MailOutlined />}
                >
                  Sign in with email
                </Button>
              </div>
              <div className="LoginModal__content--toggle">
                <p>
                  Need an account?{" "}
                  <b onClick={() => setSignIn(false)}>Sign up</b>
                </p>
              </div>
            </div>
          )}

          {!signIn && !signInWithEmail && !signUpWithEmail && (
            <>
              <h2>Join Chefs Labs</h2>
              <p>
                Create an account to receive great stories in your inbox,
                personalize your homepage, and follow authors and topics that
                you love.
              </p>
              {error && <p className="LoginModal__content--error">{error}</p>}

              <div className="LoginModal__content--btns">
                <Button
                  icon={<MailOutlined />}
                  onClick={() => setSignUpWithEmail(true)}
                >
                  Sign up with Email
                </Button>
              </div>
              <div className="LoginModal__content--toggle">
                <p>
                  Already have an account?{" "}
                  <b onClick={() => setSignIn(true)}>Sign in</b>
                </p>
              </div>
            </>
          )}

          {signInWithEmail && (
            <>
              <h2>Sign in</h2>
              {error && <p className="LoginModal__content--error">{error}</p>}
              <Form
                layout="vertical"
                name="basic"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    { required: true, message: "Please input your email!" },
                  ]}
                >
                  <Input type="email" />
                </Form.Item>

                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    { required: true, message: "Please input your password!" },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item>
                  <Link to="/changepassword">Forgot password</Link>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ margin: "auto" }}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </>
          )}

          {signUpWithEmail && (
            <>
              <h2>Sign up</h2>
              <Form
                layout="vertical"
                name="basic"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <Form.Item label="Email" name="email">
                  <Input type="email" />
                </Form.Item>

                <Form.Item label="Password" name="password">
                  <Input.Password />
                </Form.Item>

                <Form.Item
                  label="Verify Password"
                  name="verifyPassword"
                  rules={[
                    { required: true, message: "Please verify your password" },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          "The two passwords that you entered must match"
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password />
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ margin: "auto" }}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};
