import * as React from "react";

export const RecipeBook = ({ ...props }) => (
  <svg
    {...props}
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    enableBackground="new 0 0 512 512"
  >
    <path
      fill="#F18290"
      d="M472.535,504.5H124.229c-14.953,0-27.076-12.122-27.076-27.076V34.576
	c0-14.953,12.122-27.076,27.076-27.076h348.306c14.953,0,27.076,12.122,27.076,27.076v442.849
	C499.611,492.378,487.488,504.5,472.535,504.5z"
    />
    <path
      opacity="0.15"
      enableBackground="new"
      d="M472.535,504.5H124.229c-14.953,0-27.076-12.122-27.076-27.076V34.576
	c0-14.953,12.122-27.076,27.076-27.076h348.306c14.953,0,27.076,12.122,27.076,27.076v442.849
	C499.611,492.378,487.488,504.5,472.535,504.5z"
    />
    <path
      fill="#F8D9B9"
      d="M448.362,464.907H121.001V47.093h327.361c6.06,0,10.973,4.913,10.973,10.973v395.868
	C459.335,459.994,454.422,464.907,448.362,464.907z"
    />
    <path
      fill="#F18290"
      d="M387.771,504.5H39.465c-14.953,0-27.076-12.122-27.076-27.076V34.576
	C12.389,19.622,24.512,7.5,39.465,7.5h348.306c14.953,0,27.076,12.122,27.076,27.076v442.849
	C414.846,492.378,402.724,504.5,387.771,504.5z"
    />
    <path
      fill="#5D6671"
      d="M68.654,7.5H39.465c-14.953,0-27.076,12.122-27.076,27.076v442.849
	c0,14.953,12.122,27.076,27.076,27.076h29.189V7.5z"
    />
    <path
      fill="#FFFFFF"
      d="M304.27,152.163c-4.781-0.38-9.421-0.027-13.827,0.95c-4.08,0.904-8.371-0.671-10.803-4.07
	c-8.447-11.806-22.267-19.504-37.891-19.504s-29.444,7.698-37.891,19.504c-2.432,3.399-6.722,4.974-10.803,4.07
	c-4.404-0.976-9.043-1.329-13.823-0.95c-22.874,1.814-41.255,20.461-42.723,43.36c-1.586,24.763,16.192,45.705,39.657,49.148
	c3.912,0.574,6.821,3.91,6.821,7.864v65.289c0,4.161,3.373,7.534,7.534,7.534h102.455c4.161,0,7.534-3.373,7.534-7.534v-65.289
	c0-3.954,2.909-7.29,6.821-7.864c23.464-3.443,41.242-24.383,39.658-49.144C345.525,172.627,327.145,153.978,304.27,152.163z"
    />
    <rect x="182.99" y="285.83" fill="#D6D8DB" width="117.52" height="15" />
    <path
      fill="#F8D662"
      d="M348.644,84.403L323.936,72.94c-3.158-1.465-6.802-1.465-9.96,0l-24.708,11.464
	c-5.833,2.706-12.505-1.553-12.505-7.983V7.5h84.386v68.92C361.149,82.85,354.477,87.11,348.644,84.403z"
    />
    <rect
      x="426.06"
      y="47.09"
      opacity="0.1"
      enableBackground="new"
      width="15"
      height="417.81"
    />
    <g>
      <ellipse fill="#FFFFFF" cx="165.22" cy="409.64" rx="28.24" ry="22.487" />
      <ellipse fill="#FFFFFF" cx="318.28" cy="409.64" rx="28.24" ry="22.487" />
    </g>
    <g>
      <path
        fill="#5D6671"
        d="M195.118,392.671c-4.142,0-7.5-3.358-7.5-7.5c0-3.276-2.665-5.941-5.941-5.941
		c-3.275,0-5.94,2.665-5.94,5.941c0,4.142-3.358,7.5-7.5,7.5s-7.5-3.358-7.5-7.5c0-11.547,9.394-20.941,20.94-20.941
		c11.547,0,20.941,9.394,20.941,20.941C202.618,389.314,199.26,392.671,195.118,392.671z"
      />
      <path
        fill="#5D6671"
        d="M315.264,392.671c-4.142,0-7.5-3.358-7.5-7.5c0-3.276-2.665-5.941-5.941-5.941
		c-3.275,0-5.941,2.665-5.941,5.941c0,4.142-3.358,7.5-7.5,7.5s-7.5-3.358-7.5-7.5c0-11.547,9.394-20.941,20.941-20.941
		s20.941,9.394,20.941,20.941C322.764,389.314,319.406,392.671,315.264,392.671z"
      />
      <path
        fill="#5D6671"
        d="M241.75,414.876c-7.632,0-14.889-3.305-19.911-9.068c-2.721-3.123-2.396-7.86,0.727-10.581
		c3.122-2.721,7.86-2.396,10.582,0.727c2.172,2.493,5.308,3.922,8.602,3.922s6.431-1.43,8.604-3.923
		c2.721-3.124,7.459-3.448,10.582-0.726c3.122,2.722,3.448,7.459,0.726,10.582C256.639,411.571,249.381,414.876,241.75,414.876z"
      />
    </g>
    <path
      opacity="0.1"
      enableBackground="new"
      d="M39.465,7.5c-14.953,0-27.076,12.122-27.076,27.076v442.849
	c0,14.953,12.122,27.076,27.076,27.076H212.61c-114.919-64.398-82.532-416.239-78.418-497L39.465,7.5L39.465,7.5z"
    />
    <path
      fill="#7CD9D2"
      d="M449.337,166.717h-34.491V92.362h34.491c11.044,0,19.997,8.953,19.997,19.997v34.362
	C469.333,157.764,460.381,166.717,449.337,166.717z"
    />
    <path
      opacity="0.15"
      enableBackground="new"
      d="M433.059,92.362h-18.213v74.355h31.405
	C430.434,143.988,433.335,110.734,433.059,92.362z"
    />
    <path
      id="SVGCleanerId_0"
      fill="#C3E9F1"
      d="M449.337,273.07h-34.491v-74.355h34.491c11.044,0,19.997,8.953,19.997,19.997
	v34.362C469.333,264.117,460.381,273.07,449.337,273.07z"
    />
    <g>
      <path
        id="SVGCleanerId_0_1_"
        fill="#C3E9F1"
        d="M449.337,273.07h-34.491v-74.355h34.491
		c11.044,0,19.997,8.953,19.997,19.997v34.362C469.333,264.117,460.381,273.07,449.337,273.07z"
      />
    </g>
    <path
      opacity="0.15"
      enableBackground="new"
      d="M433.059,198.715h-18.213v74.355h31.405
	C430.434,250.341,433.335,217.087,433.059,198.715z"
    />
    <path
      d="M304.863,144.686c-5.402-0.428-10.802-0.058-16.043,1.105c-1.178,0.262-2.417-0.185-3.08-1.112
	c-10.143-14.176-26.588-22.639-43.991-22.639c-17.402,0-33.847,8.463-43.99,22.64c-0.663,0.926-1.898,1.372-3.081,1.111
	c-5.24-1.161-10.637-1.533-16.039-1.104c-26.58,2.108-47.91,23.757-49.615,50.357c-0.882,13.765,3.422,27.19,12.118,37.802
	c8.569,10.457,20.621,17.292,33.937,19.247c0.237,0.035,0.409,0.222,0.409,0.444v65.289c0,8.29,6.744,15.034,15.034,15.034h102.455
	c8.29,0,15.034-6.744,15.034-15.034v-65.289c0-0.222,0.172-0.409,0.41-0.444c13.314-1.954,25.365-8.788,33.934-19.245
	c8.696-10.611,12.999-24.035,12.119-37.799C352.773,168.446,331.445,146.796,304.863,144.686z M306.245,237.251
	c-7.544,1.106-13.233,7.677-13.233,15.285l-0.034,65.323l-102.488-0.034v-65.289c0-7.607-5.689-14.179-13.232-15.285
	c-19.945-2.927-34.556-21.046-33.261-41.249c1.23-19.208,16.634-34.841,35.831-36.363c1.053-0.083,2.106-0.125,3.156-0.125
	c2.854,0,5.685,0.308,8.452,0.921c7.077,1.567,14.349-1.19,18.525-7.027c7.333-10.249,19.217-16.368,31.791-16.368
	s24.458,6.119,31.792,16.367c4.177,5.838,11.447,8.595,18.525,7.028c3.786-0.839,7.693-1.105,11.61-0.796
	c19.197,1.523,34.6,17.158,35.828,36.366C340.798,216.207,326.188,234.324,306.245,237.251z M181.677,364.231
	c-11.546,0-20.94,9.394-20.94,20.941c0,4.142,3.358,7.5,7.5,7.5s7.5-3.358,7.5-7.5c0-3.276,2.665-5.941,5.94-5.941
	c3.276,0,5.941,2.665,5.941,5.941c0,4.142,3.358,7.5,7.5,7.5s7.5-3.358,7.5-7.5C202.618,373.625,193.224,364.231,181.677,364.231z
	 M260.935,395.227c-3.123-2.722-7.86-2.398-10.582,0.726c-2.173,2.493-5.309,3.923-8.604,3.923s-6.43-1.43-8.602-3.922
	c-2.722-3.123-7.46-3.448-10.582-0.727c-3.123,2.722-3.448,7.459-0.727,10.581c5.022,5.763,12.279,9.068,19.911,9.068
	c7.631,0,14.889-3.305,19.911-9.067C264.383,402.686,264.058,397.949,260.935,395.227z M499.611,73.596c4.142,0,7.5-3.358,7.5-7.5
	v-31.52C507.111,15.511,491.6,0,472.535,0H39.465C20.4,0,4.889,15.511,4.889,34.576v442.849C4.889,496.489,20.4,512,39.465,512
	h433.07c19.065,0,34.576-15.511,34.576-34.576V102.937c0-4.142-3.358-7.5-7.5-7.5s-7.5,3.358-7.5,7.5v374.487
	c0,10.794-8.782,19.576-19.576,19.576H416.25c3.841-5.57,6.096-12.313,6.096-19.576v-5.017h26.016
	c10.186,0,18.473-8.287,18.473-18.473V274.268c6.103-5.048,9.999-12.674,9.999-21.195v-34.362c0-8.52-3.896-16.147-9.999-21.194
	v-29.602c6.103-5.048,9.999-12.674,9.999-21.194v-34.362c0-8.52-3.896-16.147-9.999-21.194V58.066
	c0-10.186-8.287-18.473-18.473-18.473h-26.016v-5.017c0-7.262-2.256-14.006-6.096-19.576h56.285
	c10.794,0,19.576,8.782,19.576,19.576v31.521C492.111,70.238,495.469,73.596,499.611,73.596z M284.263,15h69.386v61.42
	c0,0.473-0.202,0.842-0.601,1.096c-0.399,0.255-0.819,0.282-1.247,0.084l-24.708-11.464c-5.17-2.398-11.102-2.399-16.274,0
	L286.111,77.6c-0.429,0.199-0.849,0.171-1.248-0.084c-0.398-0.254-0.601-0.623-0.601-1.096V15H284.263z M407.346,477.424
	c0,10.794-8.782,19.576-19.576,19.576H76.154v-43.759c0-4.142-3.358-7.5-7.5-7.5s-7.5,3.358-7.5,7.5V497H39.465
	c-10.794,0-19.576-8.782-19.576-19.576V34.576C19.889,23.782,28.671,15,39.465,15h21.689v402.944c0,4.142,3.358,7.5,7.5,7.5
	s7.5-3.358,7.5-7.5V15h193.108v61.42c0,5.593,2.814,10.729,7.529,13.739c4.714,3.01,10.558,3.402,15.633,1.047l24.707-11.463
	c1.159-0.538,2.49-0.538,3.648-0.001l24.708,11.464c2.197,1.019,4.538,1.524,6.87,1.524c3.053,0,6.089-0.865,8.762-2.571
	c4.714-3.01,7.529-8.146,7.529-13.739V15h19.122c10.794,0,19.576,8.782,19.576,19.576L407.346,477.424L407.346,477.424z
	 M422.346,54.593h26.016c1.915,0,3.473,1.558,3.473,3.473V84.98c-0.823-0.075-1.656-0.119-2.498-0.119h-26.991V54.593z
	 M422.346,99.862h26.991c6.891,0,12.497,5.606,12.497,12.497v34.362c0,6.891-5.606,12.497-12.497,12.497h-26.991V99.862z
	 M422.346,174.217h26.991c0.842,0,1.675-0.044,2.498-0.119v17.235c-0.823-0.074-1.656-0.119-2.498-0.119h-26.991V174.217z
	 M422.346,206.215h26.991c6.891,0,12.497,5.606,12.497,12.497v34.362c0,6.891-5.606,12.497-12.497,12.497h-26.991V206.215z
	 M422.346,280.57h26.991c0.842,0,1.675-0.044,2.498-0.119v173.483c0,1.915-1.558,3.473-3.473,3.473h-26.016V280.57z
	 M301.823,364.231c-11.546,0-20.941,9.394-20.941,20.941c0,4.142,3.358,7.5,7.5,7.5s7.5-3.358,7.5-7.5
	c0-3.276,2.665-5.941,5.941-5.941s5.941,2.665,5.941,5.941c0,4.142,3.358,7.5,7.5,7.5s7.5-3.358,7.5-7.5
	C322.764,373.625,313.37,364.231,301.823,364.231z"
    />
  </svg>
);
