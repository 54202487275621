import * as React from "react";
import { Card, Statistic, Button, List } from "antd";
import { CheckOutlined } from "@ant-design/icons";

export const Pricing = () => {
  const allPricingData = [
    {
      description: "Host unlimited cooking classes",
      premiumOnly: false,
    },
    {
      description: "Up to 15 people per class",
      premiumOnly: false,
    },
    {
      description: "Hour video hosting limit",
      premiumOnly: false,
    },
    {
      description: "Basic analytics",
      premiumOnly: false,
    },
    {
      description: "Satisfaction forms",
      premiumOnly: true,
    },
    {
      description: "Store your sessions",
      premiumOnly: true,
    },
    {
      description: "Up to 100 people per class",
      premiumOnly: true,
    },
    {
      description: "Host premium classes",
      premiumOnly: true,
    },
  ];
  return (
    <div className="Pricing">
      <div className="Pricing__banner">
        <h1 className="Pricing__banner__title">Pricing</h1>
        <p className="Pricing__banner__description">
          Chefy is always free up to 4 people in your class. Upgrade for more
          features, analytics and longer sessions.
        </p>
      </div>
      <div className="Pricing__main">
        <div className="Pricing__main__inner">
          <Card
            className="Pricing__card Pricing__card--free"
            size="small"
            title="Free"
          >
            <div className="Pricing__card__price">
              <Statistic value={0} prefix={"$"} precision={0} />

              <p>
                {" "}
                Create cooking classes online hosting 4 people at a time for 60
                minutes.
              </p>

              <Button type="default">Sign up for free</Button>
            </div>
            <List
              itemLayout="horizontal"
              dataSource={allPricingData}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    title={
                      !item.premiumOnly && (
                        <>
                          <CheckOutlined style={{ color: "#52c41a" }} />{" "}
                          {item.description}
                        </>
                      )
                    }
                    description={item.premiumOnly && item.description}
                  />
                </List.Item>
              )}
            />
          </Card>
          <Card
            className="Pricing__card Pricing__card--premium"
            size="small"
            title="Pro"
          >
            <div className="Pricing__card__price">
              <Statistic value={9.99} prefix={"$"} precision={2} />
              <small>monthly</small>
              <p>
                Create cooking classes online hosting 30 people at a time for up
                to 2 hours.
              </p>
              <Button type="primary">Upgrade to pro</Button>
            </div>

            <List
              itemLayout="horizontal"
              dataSource={allPricingData}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    title={
                      <>
                        <CheckOutlined style={{ color: "#52c41a" }} />{" "}
                        {item.description}
                      </>
                    }
                  />
                </List.Item>
              )}
            />
          </Card>
        </div>
      </div>
    </div>
  );
};
