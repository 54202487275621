import React, { useContext, useState } from "react";
import { Button, Select } from "antd";
import moment from "moment";
import { NewCookingEventContext } from "../../../contexts";
import { MailOutlined } from "@ant-design/icons";
import { Auth } from "aws-amplify";
import { apiBase } from "../../../helpers/utilities";
import { RoomTypeId, IChefyUser, IScheduledEvent } from "../../../models";
import { Event } from "../../Event";
import { createEvent } from "../../../helpers";

export const Review = () => {
  const { newCookingEvent } = useContext(NewCookingEventContext);
  const [userEmails, setUserEmail] = useState([]);
  const eventDate =
    newCookingEvent.date && moment(newCookingEvent.date).format("MM-DD-YYYY");

  const handleSubmit = async () => {
    const loggedInUser = await Auth.currentAuthenticatedUser();
    const participantsArray: Array<IChefyUser> = userEmails.map((email) => {
      return {
        isHost: false,
        email: email,
        userName: "",
      };
    });

    // Add current user as host
    participantsArray.push({
      cognitoUserId: loggedInUser.attributes.sub,
      isHost: true,
      email: loggedInUser.attributes.email,
      userName: "",
    });

    const scheduleEventData: IScheduledEvent = {
      recipe: {
        recipeName: newCookingEvent.title,
        ingredients: newCookingEvent.ingredientsList.join(";"),
        instructions: newCookingEvent.preparation,
        tags: newCookingEvent.tags,
      },
      room: {
        scheduledStartTime: moment(newCookingEvent.date).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        participants: userEmails.length + 1,
        roomtypeId: RoomTypeId.Free,
      },
      participants: participantsArray,
    };

    await createEvent(scheduleEventData);
  };

  return (
    <div className="Review">
      <div className="Review__share">
        <h2 className="Review__share__title">
          Review and share your event with up to 4 people
        </h2>
        <Select
          className="Review__share__input"
          id="share"
          mode="tags"
          size="large"
          maxTagCount={5}
          style={{ width: "100%" }}
          placeholder="Invite others via email"
          onChange={(val: any) => setUserEmail(val)}
          notFoundContent="There are currently no email entered"
        />{" "}
        <small>All valid emails will receive links to your cooking class</small>
        <br />
        <Button
          size="large"
          type="primary"
          onClick={handleSubmit}
          icon={<MailOutlined />}
        >
          Share
        </Button>
      </div>
      <Event
        username="Dan"
        title={newCookingEvent.title || "Ultimate Mushroom Pizza Dish"}
        videoLink={newCookingEvent.videoLink}
        imageUrl={
          (newCookingEvent.featuredImage &&
            newCookingEvent.featuredImage.secure_url) ||
          "https://preview.redd.it/ylz0r01vgd751.jpg?width=960&crop=smart&auto=webp&s=9749a55ff1b78302586150a56da0d7cdb0764dd6"
        }
        tags={newCookingEvent.tags}
        ingredientsData={newCookingEvent.ingredientsList}
        prepInstructions={newCookingEvent.preparation}
        date={eventDate}
        noSidebar={true}
      />
    </div>
  );
};
