import React, { useContext, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { EditableTable } from "./IngredientsListFormTable";
import { NewCookingEventContext } from "../../../contexts";

export const IngredientsListForm = () => {
  const { Search } = Input;
  const [newItemValue, setNewItemValue] = useState<string>("");
  const { newCookingEvent, setNewCookingEvent } = useContext(
    NewCookingEventContext
  );

  return (
    <>
      <h2>Title</h2>

      <Input
        className="IngredientsListForm__title"
        size="large"
        placeholder="Pick a cool title for your recipe"
        value={newCookingEvent.title}
        onChange={(e) => {
          setNewCookingEvent({
            ...newCookingEvent,
            title: e.target.value,
          });
        }}
      />

      <h2>Ingredients List</h2>

      <Search
        size="middle"
        className="IngredientsListForm__add"
        placeholder="1 cup of shredded 🧀"
        value={newItemValue}
        onChange={(e) => setNewItemValue(e.target.value)}
        onSearch={(value, e) => {
          if (value) {
            setNewCookingEvent({
              ...newCookingEvent,
              ingredientsList: [...newCookingEvent.ingredientsList, value],
            });

            setNewItemValue("");
          }
        }}
        enterButton={<PlusOutlined />}
      />
      <EditableTable />
    </>
  );
};
