import React, { useState, useContext } from "react";
import { Steps, Button } from "antd";
import { IngredientsListForm } from "./IngredientsListForm";
import { DatePicker } from "./DatePicker";
import { PrepWriteup } from "./PrepWriteup";
import { Review } from "./Review";
import { ImageUploader } from "./ImageUploader";
import { CategorySelect } from "./CategorySelect";
import { VideoLink } from "./VideoLink";
import { NewCookingEventContext } from "../../contexts";

export const CreateForm = () => {
  const [currentStep, setCurrentStep] = useState(2);
  const { newCookingEvent } = useContext(NewCookingEventContext);
  const { Step } = Steps;

  const isButtonDisabled = () => {
    switch (currentStep) {
      case 0:
        if (
          !newCookingEvent.title ||
          newCookingEvent.ingredientsList.length === 0
        ) {
          return true;
        }
        return false;
      case 1:
        if (!newCookingEvent.preparation) {
          return true;
        }
        return false;

      default:
        return false;
    }
  };

  return (
    <div className="CreateForm">
      <Steps size="small" current={currentStep}>
        <Step title="Ingredients list" />
        <Step title="Prep writeup" />
        <Step title="Meta data" />
        <Step title="Review and share" />
      </Steps>

      <div className="CreateForm__steps">
        {currentStep === 0 && (
          <div className="CreateForm__steps--one">
            <IngredientsListForm />
          </div>
        )}
        {currentStep === 1 && (
          <div className="CreateForm__steps-two">
            <PrepWriteup />
          </div>
        )}
        {currentStep === 2 && (
          <div className="CreateForm__steps-three">
            <ImageUploader />
            <DatePicker />
            <CategorySelect />
            <VideoLink />
          </div>
        )}

        {currentStep === 3 && (
          <div className="CreateForm__steps-four">
            <Review />
          </div>
        )}
      </div>

      <div className="CreateForm__nav">
        {currentStep !== 0 && (
          <Button
            className="CreateForm__nav--prev"
            onClick={() => setCurrentStep(currentStep - 1)}
            type="default"
          >
            {currentStep === 3 ? "Go Back and Edit" : "Previous"}
          </Button>
        )}
        {currentStep < 3 && (
          <Button
            disabled={isButtonDisabled()}
            className="CreateForm__nav--next"
            onClick={() => setCurrentStep(currentStep + 1)}
            type="primary"
          >
            Next
          </Button>
        )}
      </div>
    </div>
  );
};
