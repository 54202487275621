import React from "react";
import classNames from "classnames";

type IProps = {
  title: string;
  body: string;
  image: React.ReactNode;
  reverse?: boolean;
  cta?: React.ReactNode;
};

export const Duo = ({ image, title, body, reverse, cta }: IProps) => {
  const duoClasses = classNames({
    Duo: true,
    "Duo--reverse": reverse,
  });

  return (
    <section className={duoClasses}>
      <div className="Duo__inner">
        {!reverse && (
          <>
            <div className="Duo__inner__image Duo__inner__image--flipped">
              {image}
            </div>
            <div className="Duo__inner__copy">
              <h3>{title}</h3>
              <p>{body}</p>
              <span>{cta}</span>
            </div>
          </>
        )}

        {reverse && (
          <>
            <div className="Duo__inner__copy">
              <h3>{title}</h3>
              <p>{body}</p>
              <span>{cta}</span>
            </div>
            <div className="Duo__inner__image">{image}</div>
          </>
        )}
      </div>
    </section>
  );
};
