import React from "react";
import { List, Card, Avatar, Tag } from "antd";
import { CalendarOutlined } from "@ant-design/icons";
import { IUpcomingEvent } from "../../models";

type IProps = {
  events?: Array<IUpcomingEvent>;
};

export const EventsList = ({ events }: IProps) => {
  const { Meta } = Card;

  // const getTagsForRecipe = (event : IUpcomingEvent) =>{
  //     const tags = event.recipe.tags.map((tag, i) =>
  //       <Tag key={i} color={tag.tagColor}>{tag.tagName}</Tag>
  //     );

  //     return tags;
  // }

  return (
    <div className="EventsList">
      {/* {events && <h1 className="EventsList__title">what is this</h1>} */}
      <List
        grid={{
          gutter: 16,
          xs: 1,
          sm: 2,
          md: 3,
          lg: 3,
          xl: 3,
          xxl: 3,
        }}
        dataSource={events}
        renderItem={(item) => (
          <List.Item>
            <Card
              cover={
                <img
                  alt="example"
                  src="https://food.fnr.sndimg.com/content/dam/images/food/fullset/2019/2/19/0/KC2004_Deep-Dish-Pizza_s4x3.jpg.rend.hgtvcom.826.620.suffix/1550603328845.jpeg"
                />
              }
              actions={[
                <>
                  <Avatar src="https://pbs.twimg.com/profile_images/1207348167164989443/WXEErrNJ_400x400.jpg" />
                  <br />
                  Dan Donohue
                </>,
                <>
                  <CalendarOutlined /> <br /> August 3rd, 2020
                </>,
              ]}
            >
              <Meta
                title={
                  <a href={`/landing?eventId=${item.eventId}`}>
                    {item.recipe.recipeName}
                  </a>
                }
                description={
                  <div className="EventsList__tagslist">
                    {/* {getTagsForRecipe(item)} */}
                  </div>
                }
              />
            </Card>
          </List.Item>
        )}
      />
    </div>
  );
};
