import React, { useContext } from "react";
import { Select, Tag } from "antd";
import { NewCookingEventContext } from "../../../contexts";

export const CategorySelect = () => {
  const { newCookingEvent, setNewCookingEvent } = useContext(
    NewCookingEventContext
  );

  const options = [
    { value: "keto" },
    { value: "gluten free" },
    { value: "halal" },
    { value: "raw" },
    { value: "vegan" },
    { value: "vegetarian" },
    { value: "no nuts" },
  ];

  const tagRender = (props: any) => {
    const { color, value, closable, onClose } = props;

    return (
      <Tag
        color={color}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {value}
      </Tag>
    );
  };

  return (
    <div className="CategorySelect">
      <h2>Add categories</h2>
      <Select
        mode="multiple"
        tagRender={tagRender}
        style={{ width: "100%" }}
        options={options}
        onChange={(value) =>
          setNewCookingEvent({ ...newCookingEvent, tags: value })
        }
      />
    </div>
  );
};
