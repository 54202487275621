import React from "react";

export const Footer = () => (
  <footer className="Footer">
    © {new Date().getFullYear()}, Chefs Labs - Built with icons by{" "}
    <a href="https://illlustrations.co/" rel="noreferrer noopener">
      @realvjy
    </a>
    ,{" "}
    <a
      href="https://www.flaticon.com/authors/freepik"
      rel="noreferrer noopener"
    >
      Freepik
    </a>{" "}
    and{" "}
    <a
      href="https://www.flaticon.com/authors/vectors-market"
      rel="noreferrer noopener"
    >
      Vectors Market
    </a>
  </footer>
);
