import React, { useContext } from "react";
import { Input } from "antd";
import { NewCookingEventContext } from "../../../contexts";

export const VideoLink = () => {
  const { newCookingEvent, setNewCookingEvent } = useContext(
    NewCookingEventContext
  );

  return (
    <div className="VideoLink">
      <h2>Add video conference link (optional)</h2>
      <Input
        style={{ width: "100%" }}
        placeholder={"i.e. Zoom, Google Hangouts, Uber Conferences, Facetime"}
        onChange={(event) => {
          console.log(event.target.value);
          setNewCookingEvent({
            ...newCookingEvent,
            videoLink: event.target.value,
          });
        }}
      />
    </div>
  );
};
